import Login from "./components/Login";
import SignedIn from "./components/SignedIn";

const AppRoutes = [
    {
        index: true,
        element: <Login />
    },    
    {
        path: '/auth',
        element: <SignedIn />
    }
];

export default AppRoutes;
