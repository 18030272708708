import React, { useEffect,useState } from 'react';
import HoneywellLogo from './honeywell_logo.png';
import { getQuery } from './utilities.js';

const Login = () => {

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        localStorage.clear();
    }, []);
    
    const login = () => {
        if (getQuery("redirect_uri")) {
            localStorage.setItem('redirect_url', getQuery("redirect_uri"));
        }
        if (getQuery("state")) {
            localStorage.setItem('state', getQuery("state"));
        }
        if (getQuery("client_id")) {
            localStorage.setItem('client_id', getQuery("client_id"));
        }

        setLoading(true);
        fetch("api/settings")
            .then(res => res.json())
            .then(data =>
            {
                window.location = data.authEndpoint + "?" +
                    "resource=" + getQuery("client_id") +
                    "&client_id=" + getQuery("client_id") +
                    "&response_type=" + getQuery("response_type") +
                    "&redirect_uri=" + data.redirectUri;
            })
            .catch((error) =>
            {
                setLoading(false);
            });    
        
    }

    return(
            <div class="" style={{ display: 'block' }} >
                <div class="">
                    <div class="">
                        <div class="modal-header">
                            <img src={HoneywellLogo} alt="" />
                        </div>
                        <div class="modal-body">
                        <p>Please sign-in to your sentience account to link it to your alexa skill.</p>
                        </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-start' }} class="modal-footer" >
                        <button disabled={loading} onClick={login}> Sign -in</button>
                        </div>
                    </div>
                </div>
            </div>            
    )

}
export default Login;