export function getQuery(param) {
    param = param.replace(/[\[\]]/g, "\\$&");
    var results = new RegExp("[?&]" + param + "(=([^&#]*)|&|#|$)").exec(window.location.href);
    if (!results) {
        return null;
    }
    if (!results[2]) {
        return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}