import React from 'react';
import HoneywellLogo from './honeywell_logo.png';
import { getQuery } from './utilities.js';

const SignedIn = () => {    

    const finish = () => {               
        var redirectUrl = localStorage.getItem("redirect_url");
        var state = localStorage.getItem("state");

        window.location = redirectUrl + "?" +
            "code=" + getQuery("code") +
            "&state=" + state;        
    }

    return (
        <div class="" style={{ display: 'block' }} >
            <div class="">
                <div class="">
                    <div class="modal-header">
                        <img src={HoneywellLogo} alt="" />
                    </div>
                    <div class="modal-body">
                        <p>Thanks for signing in to the Alexa Inncom Skill.</p>
                        <p>Finish from your latest react App.</p>
                    </div>
                    <div class="modal-footer">
                        <button onClick={finish}>Finish</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SignedIn;
